import { createSignal, JSX, onMount } from "solid-js";
import { showSnackBar } from "~/shared_states/snackbar";
import AppWebView from "~/utils/app_web_view/trigger_app_events";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

export default function Diwali() {
  let subheadingStyle = "text-2xl text-baseSecondaryMedium font-semibold";
  let headingStyle = "text-[24px] text-center text-white font-bold";
  let subtitleStyle = "text-[20px] text-center text-[#F9E8AC] font-semibold";

  return (
    <div class="noScrollbar flex h-full w-full flex-col items-center overflow-y-scroll bg-black">
      <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>

      <div class="flex h-max max-w-[580px]  flex-col items-center justify-start   font-inter text-white">
        <Lottie />
        <Spacer height={42} />
        <SlideUpAnimation>{buildTile1}</SlideUpAnimation>
        <SlideUpAnimation>{buildTile2}</SlideUpAnimation>
        <SlideUpAnimation>{buildTile3}</SlideUpAnimation>
        <Footer />
      </div>
    </div>
  );

  function Lottie() {
    return (
      <div class="h-[350px]">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="https://lottie.host/47db8513-3c56-4afc-bf1a-35b6c8d269d0/OhF82Y9pOX.json"
        ></lottie-player>
      </div>
    );
  }

  function buildBottomCta(props: { text: string; onClick?: () => void }) {
    return (
      <div
        onClick={props.onClick}
        class="flex flex-row items-center justify-center"
        classList={{
          subtitleStyle: true,
        }}
      >
        <span class={`${subtitleStyle}`}>{props.text}</span>
        <Spacer width={6} />
        <PhosphorIcon
          class={`${subtitleStyle}`}
          name="caret-right"
          fontSize={16}
        />
      </div>
    );
  }

  function buildTile1() {
    return (
      <div class="flex flex-col items-center">
        <span class={`${subheadingStyle} `}>This diwali send a</span>
        <span class={`${headingStyle}`}>Gift to your loved ones</span>
        <Spacer height={16} />

        <div class="h-[250px] px-14">
          <lottie-player
            autoplay
            mode="normal"
            src="https://lottie.host/d9bf1f95-1b90-4694-97cd-cbbe5348dae7/AMLJr63tkV.json"
          ></lottie-player>
        </div>
        <Spacer height={16} />
        {buildBottomCta({
          text: "Choose from 400+ brands",
          onClick: () => {
            AppWebView.deeplinkTrigger(
              "hubble://myhubble.money/v4/shop/search"
            );
          },
        })}
      </div>
    );
  }

  function buildTile2() {
    return (
      <div class="flex flex-col items-center">
        <span class={`${subheadingStyle}`}>A gift of your choice</span>
        <span class={`${headingStyle}`}>Elegantly wrapped by us</span>
        <div class="h-[210px] px-24">
          <img
            src="https://assets.myhubble.money/web-assets/collection-3d-gift-boxes.png "
            alt="loading"
            height="160px"
          />
        </div>
        <Spacer height={2} />
        <div class="z-50">
          {buildBottomCta({
            text: "How they will find it",
            onClick: () => {
              AppWebView.deeplinkTrigger(
                "hubble://myhubble.money/v4/gifting-preview?name=Diw"
              );
            },
          })}
        </div>
      </div>
    );
  }

  function buildTile3() {
    return (
      <div class="flex flex-col items-center">
        <span class={`${subheadingStyle}`}>Order a box of happiness</span>
        <span class={`${headingStyle}`}>
          Available on Swiggy
          <br />
          and zepto
        </span>
        <Spacer height={24} />
        <div class="flex flex-row gap-x-12">
          <div
            onClick={() => {
              AppWebView.externalLinkTrigger(
                "https://www.swiggy.com/instamart/search?custom_back=true&query=hubble+gift+card"
              );
            }}
            class="relative flex"
          >
            <img
              src="https://assets.myhubble.money/web-assets/instamart_logo_round.png"
              alt="Swiggy"
              width={100}
              height={100}
            />
            <div class="absolute inset-0 h-[100px] w-[100px] rounded-full bg-orange opacity-20"></div>
          </div>
          <div
            class="relative flex"
            onClick={() => {
              AppWebView.externalLinkTrigger(
                "https://www.zeptonow.com/pn/giva-gift-card-worth-rs1100-/pvid/32392951-ebea-4643-a4c8-0935914cb1e7"
              );
            }}
          >
            <img
              src="https://assets.myhubble.money/web-assets/zepto_logo_round.png"
              alt="Zepto"
              width={100}
              height={100}
            />
            <div class="absolute inset-0 h-[100px] w-[100px] rounded-full bg-purple-300 opacity-20"></div>
          </div>
        </div>
        <Spacer height={24} />
        {buildBottomCta({
          text: "Gift Box details",
          onClick: () => {
            AppWebView.deeplinkTrigger(
              "hubble://myhubble.money/public/webview/?title=&url=https://rewards.myhubble.money/gift"
            );
          },
        })}
      </div>
    );
  }

  function SlideUpAnimation({ children }: { children: () => JSX.Element }) {
    let fadeAnimationInitialState = "opacity-0 translate-y-7";
    let fadeAnimationFinalState = "opacity-100 translate-x-0 ";

    let observer: IntersectionObserver;
    let observerRef: Element | undefined = undefined;

    const [isTile1Visible, setIsTile1Visible] = createSignal(false);

    const ioConfiguration = {
      rootMargin: "-50% 0% -50% 0%",
      threshold: 0,
    };

    onMount(() => {
      observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsTile1Visible(true);
        }
      }, ioConfiguration);
      if (observerRef) {
        observer.observe(observerRef);
      }
    });
    return (
      <>
        <div
          class="transition-all duration-200"
          ref={observerRef}
          classList={{
            [fadeAnimationInitialState]: !isTile1Visible(),
            [fadeAnimationFinalState]: isTile1Visible(),
          }}
        >
          {children()}
        </div>
        <div class="h-[100px]"></div>
      </>
    );
  }

  function Footer() {
    return (
      <div class="flex w-full flex-col px-8 md:max-w-[320px]">
        <Spacer height={12} />
        <ActionButton
          text="Corporate gifting"
          onClick={() => {
            AppWebView.deeplinkTrigger(
              "hubble://myhubble.money/public/webview/?title=&url=https://rewards.myhubble.money/employee-rewards"
            );
          }}
        />
        <ActionButton
          text="Hubble assured"
          onClick={() => {
            AppWebView.flowTrigger("assured");
          }}
        />
        <ActionButton
          text="Contact us"
          onClick={() => {
            AppWebView.flowTrigger("chat_support");
          }}
        />
      </div>
    );
  }

  function ActionButton(props: {
    text: string;
    onClick: () => void;
    defaultEventProps?: Record<string, any>;
  }) {
    return (
      <div>
        <div
          class="flex cursor-pointer flex-row items-center py-[18px]  text-baseSecondaryMedium"
          onClick={() => {
            props.onClick();
          }}
        >
          <div class=" grow  text-[13px] font-medium tracking-[1.8px]">
            {props.text}
          </div>
          <i class="ph ph-caret-right"></i>
        </div>
        <Divider bgColor="#333232" />
      </div>
    );
  }
}
